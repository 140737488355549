import { config } from './config/config';

// app init
function init(): void {

}

// document ready
document.addEventListener("DOMContentLoaded", function(){
  // run init
  init();
});
